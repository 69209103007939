import styled from "styled-components";

const Container = styled.div`
  background: var(--background-color-white);
  height: 100%;
  width: 100%;

  position: relative;

  @media print {
    height: 1123px;
    width: 793px;
  }
`;

const MainContent = styled.main`
  height: 632px;

  margin: -40px 20px;

  border: 1px solid var(--border-color-purple-gray);

  @media print {
    height: 632px;
  }
`;

const Content = styled.div`
  width: 100%;
`;

const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  height: 31px;

  background-color: var(--background-color-gainsboro);
`;

const HeaderLabel = styled.h4`
  width: 100%;

  text-align: center;

  font-size: 12px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 11.42px;

  color: var(--text-color-slate-gray);

  &:nth-child(2) {
    text-align: left;
  }

  &:first-child {
    width: 100px;
  }
`;

export { Container, MainContent, Content, HeaderContent, HeaderLabel };

import { LocalidadeBody } from "../../types/apiResponse";
import FooterTable from "../FooterTable";
import HeaderSurvey from "../HeaderSurvey";
import LineLocation from "../LineLocation";

import {
  Container,
  MainContent,
  Content,
  HeaderContent,
  HeaderLabel,
} from "./styles";

interface LocationsTableProps {
  data: LocalidadeBody[];
  tituloPesquisa: string;
  localidade: string;
  amostraTotal: number;
  operadoresTotal: number;
  page: number;
}

function LocationsTable({ data, tituloPesquisa, localidade, amostraTotal, operadoresTotal, page }: LocationsTableProps) {
  return (
    <Container className="page">
      <HeaderSurvey
        surveyTitle={tituloPesquisa}
        surveyLocation={localidade}
        surveyTotalOfSamples={amostraTotal}
        surveyTotalOfOperators={operadoresTotal}
      />

      <MainContent>
        <Content>
          <HeaderContent>
            <HeaderLabel />
            <HeaderLabel>Localidade</HeaderLabel>
            <HeaderLabel>Amostra percentual</HeaderLabel>
            <HeaderLabel>Amostra</HeaderLabel>
            <HeaderLabel>Operadores</HeaderLabel>
          </HeaderContent>
          {data.map((item: LocalidadeBody) => (
            <LineLocation
              key={item.id}
              headerData={item}
            />
          ))}
        </Content>
      </MainContent>

      <FooterTable label="Distribuição de amostras" page={page} />
    </Container>
  );
}

export default LocationsTable;

import TensaiLogo from "../../assets/icons/tensai-logo.svg";
import BackgroundImage from "../../assets/images/background-cover.png";

import {
  Container,
  ImageContent,
  Content,
  Logo,
} from "./styles";

function End() {
  return (
    <Container className="page">
      <ImageContent alt="Background" src={BackgroundImage} />
      <Content>
        <Logo alt="Tensai" src={TensaiLogo} />
      </Content>
    </Container>
  );
}

export default End;

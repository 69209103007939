import styled from "styled-components";

interface CardContentProps {
  hasBordersLeftAndRight?: boolean;
  marginLeft?: boolean;
  marginRight?: boolean;
}

const Container = styled.header`
  width: 100%;
  height: 204px;

  padding: 30px 0 63px 21px;

  background-color: var(--background-color-purple-gray);
`;

const HeaderSurveyContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  margin-left: 15px;
`;

const TensaiIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const SurveyContent = styled.div``;

const SurveyLabel = styled.span`
  font-size: 8px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 10.89px;

  color: var(--text-color-gray);
`;

const SurveyTitle = styled.h4`
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 19.07px;

  color: var(--text-color-purple);
`;

const Card = styled.div`
  margin-top: 14px;
  padding: 16px 23px;

  height: 66px;

  border: 1px solid var(--border-color-white);

  display: flex;
  align-items: center;
  gap: 5px;
`;

const CardContent = styled.div<CardContentProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  border-left: ${(props) =>
    props?.hasBordersLeftAndRight ? "1px solid var(--border-color-white)" : ""};
  border-right: ${(props) =>
    props?.hasBordersLeftAndRight ? "1px solid var(--border-color-white)" : ""};

  padding-left: ${(props) => (props?.hasBordersLeftAndRight ? "30px" : "")};

  padding-right: ${(props) => (props?.hasBordersLeftAndRight ? "30px" : "")};

  margin-right: ${(props) => (props?.marginRight ? "30px" : "")};
`;

const CardLabel = styled.label`
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 16.34px;

  color: var(--text-color-dark-gray);
`;

const CardString = styled.span`
  font-size: 12px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 16.34px;

  color: var(--text-color-slate-gray);
`;

const CardNumber = styled.span`
  font-size: 12px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 16.34px;

  color: var(--text-color-slate-gray);
`;

export {
  Container,
  HeaderSurveyContent,
  TensaiIcon,
  SurveyContent,
  SurveyLabel,
  SurveyTitle,
  Card,
  CardContent,
  CardLabel,
  CardString,
  CardNumber,
};

import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 31px;

  padding: 10px 47px 10px 14px;

  background-color: var(--background-color-gainsboro);
`;

const HeaderText = styled.span`
  font-size: 12px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 11.42px;

  color: var(--text-color-slate-gray);
`;

const OperatorInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
`;

const SamplesAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardContent = styled.div`
  background-color: var(--background-color-white);

  padding: 11px 54px;
`;

const CardTable = styled.div`
  width: 100%;
  max-width: 284px;
  padding-left: 36px;

  position: relative;

  &::before {
    content: '';
    width: 1px;
    height: calc(100% - 4.5px);
    position: absolute;
    left: 0px;
    border-left: 1px solid var(--background-color-thesad-purple);
  }
`;

const RowHeader = styled.div`
  width: 100%;

  margin: 14px 0 22px 0;
`;

const GroupColumns = styled.div`
  display: flex;
  gap: 64px;
`;

const ColumnHeader = styled.div`
  width: 100%;

  font-size: 9px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 8.57px;

  color: var(--text-color-slate-gray);
`;

const RowContent = styled.div`
  width: 100%;
  margin-bottom: 18px;

  position: relative;

    &::before {
      content: "";
      width: 25px;
      height: 1px;
      background-color: var(--background-color-thesad-purple);

      position: absolute;

      top: calc(50% - .5px);
      left: -14.5%;
    }
`;

const ColumnContent = styled.div`
  width: 100%;

  font-size: 9px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 8.57px;

  color: var(--text-color-slate-gray);

  &:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export {
  Container,
  CardHeader,
  HeaderText,
  OperatorInfo,
  SamplesAmount,
  CardContent,
  CardTable,
  RowHeader,
  GroupColumns,
  ColumnHeader,
  RowContent,
  ColumnContent,
};

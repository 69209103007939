import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  width: 100%;

  position: relative;
  background-color: var(--background-color-purple);

  padding: 47px 63px 85px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    height: 1123px;
    width: 793px;
  }
`;

const ImageContent = styled.img`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;

  mix-blend-mode: overlay;
`;

const Content = styled.div`
  width: 100%;
  height: 100vh;

  padding: 47px 63px 85px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
`;

const Logo = styled.img`
  width: 245px;
  height: 80px;
`;

export { Container, ImageContent, Content, Logo };

import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  width: 100%;

  position: relative;
  background-color: var(--background-color-purple);

  @media print {
    height: 1123px;
    width: 793px;
  }
`;

const ImageContent = styled.img`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;

  mix-blend-mode: overlay;
`;

const Content = styled.div`
  width: 100%;
  height: 100vh;

  padding: 47px 63px 85px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  position: absolute;
  top: 0;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img``;

const CopyrightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 7px;
`;

const Version = styled.span`
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 13.33px;

  color: var(--text-color-white);
`;

const CurrentYearOfCopy = styled.span`
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 13.33px;

  color: var(--text-color-white);
`;

const FooterOfCover = styled.footer`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
`;

const FooterIcon = styled.img`
  width: 48px;
  height: 56px;
`;

const FooterDescription = styled.span`
  max-width: 360px;

  font-size: 47.73px;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 45.43px;

  color: var(--text-color-white);
`;

const FooterDescriptionStronger = styled.strong`
  font-size: 47.73px;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 45.43px;

  color: var(--text-color-white);
`;

const FooterMediumBar = styled.div`
  width: 112px;
  height: 6px;

  margin-top: 17px;

  background-color: var(--background-color-thesad-purple);
`;

export {
  Container,
  ImageContent,
  Content,
  Header,
  Logo,
  CopyrightContainer,
  Version,
  CurrentYearOfCopy,
  FooterOfCover,
  FooterIcon,
  FooterDescriptionStronger,
  FooterDescription,
  FooterMediumBar,
};

import { OperatorProps } from '../../types/apiResponse'
import FooterTable from '../FooterTable'
import HeaderSurvey from '../HeaderSurvey'
import OperatorCard from '../OperatorCard'
import { Container, MainContent } from './styles'

interface OperatorsTableProps {
  data: OperatorProps[];
  tituloPesquisa: string;
  localidade: string;
  amostraTotal: number;
  operadoresTotal: number;
  page: number;
  contadorRef: number;
}

function OperatorsTable({ data, tituloPesquisa, localidade, amostraTotal, operadoresTotal, page, contadorRef }: OperatorsTableProps) {
  // let contador = contadorRef;

  return (
    <Container className="page">
      <HeaderSurvey
        surveyTitle={tituloPesquisa}
        surveyLocation={localidade}
        surveyTotalOfSamples={amostraTotal}
        surveyTotalOfOperators={operadoresTotal}
      />

      <MainContent data-testid="operators-page">
        {data.map((item, index) => {
          return (
            <OperatorCard
              key={item.operador.id}
              iArray={index + 1}
              operador={item.operador}
              localidades={item.localidades}
            />
          )
        })}
      </MainContent>

      <FooterTable label='Distribuição de operadores por localidade' page={page} />
    </Container>
  )
}

export default OperatorsTable

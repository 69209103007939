import { useEffect, useState } from 'react'
import Actions from '../../components/Actions'

import Cover from '../../components/Cover'
import CoverForTopic from '../../components/CoverForTopic'
import End from '../../components/End'
import LocationsTable from '../../components/LocationsTable'
import OperatorsTable from '../../components/OperatorsTable'
import { useQuery } from '../../hooks/useQuery'

import api from '../../services/api'

import { ContainerDefault } from '../../styles/Global'
import { Data, RespondeData } from '../../types/apiResponse'
import { formatDataTemplate } from '../../utils/formatData';
import { formatDataOperator } from '../../utils/formatDataOperator'
import { formatDataOperatorsPerPage } from '../../utils/formatDataOperatorsPerPage'
import { formatDataPerPageArray } from '../../utils/formatDataPerpage'

function Main() {
  const query = useQuery();
  const id = query.get('id');
  const token = query.get('token');

  const [reporterData, setReporterData] = useState<RespondeData | null>(null);

  const [dateString, setDateString] = useState("");

  const fetchData = async (): Promise<void> => {
    try {
      const formattedData = formatDataTemplate(new Date().toISOString())
      setDateString(formattedData);

      const { data: operatorData } = await api.get(`pesquisas/${id}/operadores`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      const { data } = await api.get<Data>(`pesquisas/buscar-editar/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })

      const localidadesPerPage = formatDataPerPageArray(data.vinculos);

      const operadores = formatDataOperator(data.vinculos, operatorData);

      //
      const operadoresPorPagina = formatDataOperatorsPerPage(operadores);

      setReporterData({
        tituloPesquisa: data.descricaoPesquisa.titulo,
        amostraTotal: data.configuracaoPesquisa.amostrasPresenciais,
        operadoresTotal: operadores.length,
        localidade: data.vinculos[0].localidade.nomeLocalidadePai,
        localidades: localidadesPerPage,
        operadores: operadoresPorPagina,
      })
    } catch (error) {
      console.log('Error', error)
    }
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Actions />
      <ContainerDefault>
        {(reporterData) && (
          <>
            <Cover />
            <CoverForTopic
              titleOfSurvey={reporterData.tituloPesquisa}
              dateOfSurvey={dateString}
              titleOfTopic='Distribuição de amostras'
            />
            {
              reporterData.localidades.map((item, index) => (
                <LocationsTable
                  key={index}
                  data={item}
                  page={2 + index}
                  tituloPesquisa={reporterData.tituloPesquisa}
                  localidade={reporterData.localidade}
                  amostraTotal={reporterData.amostraTotal}
                  operadoresTotal={reporterData.operadoresTotal}
                />
              ))
            }
            <CoverForTopic
              titleOfSurvey={reporterData.tituloPesquisa}
              dateOfSurvey={dateString}
              titleOfTopic='Distribuição de operadores por localidade'
            />
            {
              reporterData.operadores.map((item, index) => {
                return (
                  <OperatorsTable
                    key={index}
                    tituloPesquisa={reporterData.tituloPesquisa}
                    localidade={reporterData.localidade}
                    amostraTotal={reporterData.amostraTotal}
                    operadoresTotal={reporterData.operadoresTotal}
                    data={item}
                    contadorRef={reporterData.localidades.length + 3}
                    page={reporterData.localidades.length + 3 + index}
                  />
                )
              })
            }
            <End />
          </>
        )}
      </ContainerDefault>
    </>
  )
}

export default Main;

import styled from "styled-components";

const Container = styled.footer`
  width: 100%;

  padding: 50px 20px 0;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media print {
    padding: 0 20px;

    position: absolute;
    bottom: 0;
  }
`;

const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterLabel = styled.span`
  font-size: 10px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 13.62px;

  color: var(--text-color-purple);
`;

const FlagFooterContent = styled.div`
  width: 100%;
  max-width: 294px;

  margin-top: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlagFooterPurpleGray = styled.div`
  width: 100%;
  height: 5px;

  background-color: var(--background-color-purple-gray);
`;

const FlagFooterThesadPurple = styled.div`
  width: 100%;
  height: 5px;

  background-color: var(--background-color-thesad-purple);
`;

export {
  Container,
  ContentFooter,
  FooterLabel,
  FlagFooterContent,
  FlagFooterPurpleGray,
  FlagFooterThesadPurple,
};

import styled from 'styled-components';

export const Container = styled.div`
  z-index: 2;
  position: fixed;

  top: 40px;
  right: 40px;

  display: flex;
  gap: 15px;

  @media print {
    display: none;
  }
`;

export const IconContainer = styled.div`
  width: 73px;
  height: 73px;
  background-color: var(--background-color-dark-gray);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: background-color .3s;

  &:hover {
    background-color: var(--background-color-slate-gray);
  }

  &:active {
    background-color: var(--background-color-light-violet);
  }
`;

export const IconImage = styled.img`

`
import {
  CardContent,
  CardHeader,
  CardTable,
  ColumnContent,
  ColumnHeader,
  Container,
  GroupColumns,
  HeaderText,
  OperatorInfo,
  RowContent,
  RowHeader,
  SamplesAmount
} from './styles'

interface OperatorData {
  id: number;
  nome: string;
  flag?: boolean;
  position?: number;
  amostrasTotais?: number;
}

interface LocationsData {
  id: number
  nome: string
  amostra: number
}

interface OperatorCardProps {
  iArray: number
  operador: OperatorData
  localidades: LocationsData[]
}

function OperatorCard({ iArray, operador, localidades }: OperatorCardProps) {
  return (
    <Container>
      <CardHeader>
        <OperatorInfo>
          <HeaderText>{!operador.flag && `${operador.position}`}</HeaderText>
          <HeaderText>
            {
              operador.flag ? "...continuação" : `${operador.nome}`
            }
          </HeaderText>
        </OperatorInfo>

        <SamplesAmount>
          <HeaderText>Coleta total: {operador.amostrasTotais}</HeaderText>
        </SamplesAmount>
      </CardHeader>

      <CardContent>
        <CardTable>
          <RowHeader>
            <GroupColumns>
              <ColumnHeader>Localidades</ColumnHeader>
              <ColumnHeader>Coleta da localidade</ColumnHeader>
            </GroupColumns>
          </RowHeader>

          {localidades &&
            localidades.map((location) => (
              <RowContent key={location.id}>
                <GroupColumns>
                  <ColumnContent>{location.nome}</ColumnContent>
                  <ColumnContent>{location.amostra}</ColumnContent>
                </GroupColumns>
              </RowContent>
            ))}
        </CardTable>
      </CardContent>
    </Container>
  )
}

export default OperatorCard

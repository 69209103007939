import { LocalidadeBody } from "../../types/apiResponse";
import {
  Container,
  SectionHeader,
  SectionLabel,
  ContentColumn,
  ContentGroup,
  ContentLabel,
  EmptyContent,
  EmptyLine,
  SectionContent,
} from "./styles";

interface ContentTable {
  id: number;
  localidade: string;
  amostra: number;
  operadores: number;
}

interface LineLocationProps {
  headerData: LocalidadeBody;
  contentData?: ContentTable[];
}

function LineLocation({ headerData, contentData }: LineLocationProps) {
  return (
    <Container>
      <SectionHeader>
        <SectionLabel></SectionLabel>
        <SectionLabel>{headerData.nome}</SectionLabel>
        <SectionLabel isBold>{headerData.amostraPercentual}%</SectionLabel>
        <SectionLabel>{headerData.amostraTotal}</SectionLabel>
        <SectionLabel>{headerData.operadoresTotal}</SectionLabel>
      </SectionHeader>
      <SectionContent>
        <EmptyContent />
        <ContentGroup>
          {contentData &&
            contentData.map((content) => (
              <ContentColumn key={content.id}>
                <ContentLabel>{content.localidade}</ContentLabel>
                <ContentLabel>
                  <EmptyLine />
                </ContentLabel>
                <ContentLabel>{content.amostra}</ContentLabel>
                <ContentLabel>{content.operadores}</ContentLabel>
              </ContentColumn>
            ))}
        </ContentGroup>
      </SectionContent>
      {/* TODO: LISTAGEM DE LOCALIDADES NETAS */}
      {/* {contentData &&
        contentData.map((content) => (
          <SectionContent>
            <EmptyContent />
            <ContentGroup key={content.id}>
              <ContentColumn>
                <ContentLabel>{content.localidade}</ContentLabel>
                <ContentLabel>
                  <EmptyLine />
                </ContentLabel>
                <ContentLabel>{content.amostra}</ContentLabel>
                <ContentLabel>{content.operadores}</ContentLabel>
              </ContentColumn>
            </ContentGroup>
          </SectionContent>
        ))} */}
    </Container>
  );
}

export default LineLocation;

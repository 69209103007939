import styled from "styled-components";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: var(--background-color-white);

  padding: 40px 0px 0px 46px;

  position: relative;

  @media print {
    height: 1123px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 66px;

  align-self: flex-end;

  background-color: var(--background-color-light-gray);

  padding: 15px 12px;

  display: flex;
  flex-direction: column;
`;

const TitleOfHeader = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 16.34px;

  color: var(--text-color-purple);
`;

const DescriptionOfHeader = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 19.07px;

  color: var(--text-color-purple);
`;

const Main = styled.div`
  margin-top: 132px;

  display: flex;
  flex-direction: column;

  gap: 14px;
`;

const FooterIcon = styled.img`
  width: 48px;
  height: 56px;
`;

const DateDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 16.25px;

  color: var(--text-color-dark-purple);
`;

const TitleOfPage = styled.h2`
  width: 280px;

  font-size: 37px;
  font-weight: 700;
  font-family: "Open Sans";
  line-height: 54.06px;

  color: var(--text-color-purple);
`;

const LargeBar = styled.div`
  width: 144px;
  height: 3px;

  background-color: var(--background-color-thesad-purple);
`;

const FooterBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 114px;
  display: flex;

  @media print {
    position: absolute;
    bottom: 0;
    left: 310px;
  }
`;

const FooterPurleDarkBar = styled.div`
  width: 369px;
  height: 12px;

  background-color: var(--background-color-purple);
`;

const FooterPurleLightBar = styled.div`
  width: 113px;
  height: 12px;

  background-color: var(--background-color-thesad-purple);
`;

export {
  Container,
  Header,
  TitleOfHeader,
  DescriptionOfHeader,
  Main,
  FooterIcon,
  DateDescription,
  TitleOfPage,
  LargeBar,
  FooterBar,
  FooterPurleDarkBar,
  FooterPurleLightBar,
};

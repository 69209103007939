import { parseISO, format } from "date-fns";

import pt from "date-fns/locale/pt-BR";

export function formatDataTemplate(data: string) {
  const todayReporter = parseISO(data);

  const formattedDate = format(
    todayReporter,
    "dd 'de' MMMM 'de' y ' - 'HH:mm",
    { locale: pt }
  );

  return formattedDate;
}

import { OperatorProps, Vinculos } from "../types/apiResponse";

export function formatDataOperator(data: Vinculos[], operatorData: any) {
  const operadores = data.reduce((acc, vinculo) => {
    vinculo.operadores.forEach((operador, i) => {
      const index = acc.findIndex(
        (item) => item.operador.id === operador.idOperador
      );
      if (index !== -1) {
        acc[index] = {
          ...acc[index],
          localidades: [
            ...(acc[index]?.localidades ? acc[index].localidades : []),
            ...(acc[index]?.localidades.find(
              (item) => item.nome === vinculo.localidade.nome
            )
              ? []
              : [
                  {
                    id: vinculo.localidade.id,
                    nome: vinculo.localidade.nome,
                    amostra: vinculo.operadores[i].cotaValor,
                  },
                ]),
          ],
        };
      } else {
        acc = [
          ...acc,
          {
            operador: {
              id: operador.idOperador,
              nome: operatorData[operador.idOperador]
                ? operatorData[operador.idOperador]
                : "",
            },
            localidades: [
              {
                id: vinculo.localidade.id,
                nome: vinculo.localidade.nome,
                amostra: vinculo.operadores[i].cotaValor,
              },
            ],
          },
        ];
      }
    });
    return acc;
  }, [] as OperatorProps[]);

  return operadores;
}

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from '../pages/Main';
import {getRelativePath} from "../utils/relativePath";

export function AppRoutes() {
  return (
    <BrowserRouter basename={getRelativePath()}>
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
    </BrowserRouter>
  );
}

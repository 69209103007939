import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  :root{
    --font-family: 'Open Sans', sans-serif;

    /* Backgrounds Colors */
    --app-background-color: #424242;
    --background-color-white: #FFFFFF;
    --background-color-gainsboro: #FAFAFA;
    --background-color-light-gray: #F4F4F4;
    --background-color-purple-gray: #F4EDF6;
    --background-color-purple: #610082;
    --background-color-thesad-purple: #D5BADE;
    --background-color-dark-gray: #293039;
    --background-color-light-violet: #8F00BF;
    --background-color-slate-gray: #495F6E;

    /* Texts Colors */
    --text-color-white: #FFFFFF;
    --text-color-purple: #610082;
    --text-color-dark-purple: #260033;
    --text-color-gray: #A2A1A1;
    --text-color-dark-gray: #293039;
    --text-color-slate-gray: #495F6E;

    /* Borders Colors */
    --border-color-white: #FFFFFF;
    --border-color-purple-gray: #F4EDF6;
    --border-color-light-gray: #F4F4F4;
  }

  @page {
    size: A4;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  html {
    font-size: 62.5% !important;
  }

  body {
    font-size: 1.2rem;
    /* position: relative; */

    font-family: var(--font-family);
  }

  html, body, #root {
    height: 100%;
  }

  body, html {
    background-color: var(--app-background-color);
    font-family: var(--font-family);
  }

  .page {
    margin: 0 auto;
    margin-bottom: 0.846cm;

    &:first-child {
      margin-top: 0.846cm;
    }

    @media print {
      margin: 0 !important;
    }
  }
`;

const ContainerDefault = styled.div`
  width: 100%;
  // Comprimento de um papel A4
  max-width: 21cm;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 0 auto;

  @media print {
    max-width: 21cm;
    margin: 0;

    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: column;
  }
`;

export {
  // estilo global
  GlobalStyle,
  // Container
  ContainerDefault,
};

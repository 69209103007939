import styled from 'styled-components';

interface SectionLabelProps {
  isBold?: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  background-color: var(--background-color-white);

  height: 38px;
`;

const SectionLabel = styled.h4<SectionLabelProps>`
  width: 100%;

  text-align: center;

  font-size: 11px;
  font-weight: ${(props) => props.isBold ? '700' : '400'};
  font-family: "Open Sans";
  line-height: 10.47px;

  color: var(--text-color-slate-gray);

  &:nth-child(2) {
    text-align: left;
    max-width: 204px;
    word-break: break-word;
  }

  &:first-child {
    width: 100px;
  }
`;

const SectionContent = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  /* flex-direction: column; */

  border-top: 1px solid var(--border-color-light-gray);
  border-bottom: 1px solid var(--border-color-light-gray);
`;

const EmptyContent = styled.div`
  width: 100%;
  max-width: 25px;

  height: 100%;

  border-right: 1px solid var(--border-color-purple-gray);
`;

const ContentGroup = styled.div`
  width: 100%;
  height: 100%;

  border-left: 1px solid var(--border-color-purple-gray);
`;

const ContentColumn = styled.div`
  width: 100%;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  &:nth-child(even) {
    background-color: var(--background-color-light-gray);
  }
`;

const ContentLabel = styled.h4`
  width: 100%;

  text-align: center;

  font-size: 9px;
  font-weight: 400;
  font-family: "Open Sans";
  line-height: 12.26px;

  color: var(--text-color-slate-gray);

  &:nth-child(1) {
    text-align: left;
    padding-left: 20px;
    max-width: 128px;
    word-break: break-word;
  }
`;

const EmptyLine = styled.div`
  height: 1px;
  width: 106px;

  margin: 0 auto;

  background-color: var(--background-color-light-gray);
`;

export {
  Container,
  SectionHeader,
  SectionLabel,
  ContentColumn,
  ContentGroup,
  ContentLabel,
  EmptyContent,
  EmptyLine,
  SectionContent,
}


import { useEffect, useState } from "react";

import TensaiLogo from "../../assets/icons/tensai-logo.svg";
import IconCover from "../../assets/icons/icon-cover.svg";

import BackgroundImage from "../../assets/images/background-cover.png";

import {
  Container,
  ImageContent,
  Content,
  Header,
  Logo,
  CopyrightContainer,
  Version,
  CurrentYearOfCopy,
  FooterOfCover,
  FooterIcon,
  FooterDescription,
  FooterDescriptionStronger,
  FooterMediumBar,
} from "./styles";

function Cover() {
  const [currentYear, setCurrentYear] = useState("");

  useEffect(() => {
    const currentDate = new Date();

    setCurrentYear(String(currentDate.getFullYear()));
  }, []);

  return (
    <Container className="page">
      <ImageContent alt="Background" src={BackgroundImage} />
      <Content>
        <Header>
          <Logo alt="Tensai" src={TensaiLogo} />
          <CopyrightContainer>
            <Version>Versão 1.0</Version>
            <CurrentYearOfCopy>&copy; Tensai - {currentYear}</CurrentYearOfCopy>
          </CopyrightContainer>
        </Header>

        <FooterOfCover>
          <FooterIcon alt="Tensai" src={IconCover} />
          <FooterDescription>
            Relatório de&nbsp;
            <FooterDescriptionStronger>
              distribuição de amostras
            </FooterDescriptionStronger>
          </FooterDescription>
          <FooterMediumBar />
        </FooterOfCover>
      </Content>
    </Container>
  );
}

export default Cover;

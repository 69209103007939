import { LocalidadeBody, Vinculos } from "../types/apiResponse";

export function formatDataPerPageArray(data: Vinculos[]) {
  const localidades = data.map((item) => ({
    id: item.localidade.id,
    nome: item.localidade.nome,
    amostraTotal: item.cotaValor,
    amostraPercentual: Number(item.cotaPercentual.toFixed(2)),
    operadoresTotal: item.operadores.length,
  }));

  const localidadesPerPage = localidades.reduce((acc, localidade, index) => {
    const page = Math.ceil((index + 1) / 15);

    acc[page] = [...(acc[page] ? acc[page] : []), localidade];

    return acc;
  }, [] as LocalidadeBody[][]);

  return localidadesPerPage;
}

import PalindromoIcon from "../../assets/icons/icon-cover.svg";

import {
  Container,
  Header,
  TitleOfHeader,
  DescriptionOfHeader,
  Main,
  FooterIcon,
  DateDescription,
  TitleOfPage,
  LargeBar,
  FooterBar,
  FooterPurleDarkBar,
  FooterPurleLightBar,
} from "./styles";

interface CoverForTopicProps {
  titleOfSurvey: string;
  dateOfSurvey: string;
  titleOfTopic: string;
}

function CoverForTopic({
  titleOfSurvey,
  dateOfSurvey,
  titleOfTopic,
}: CoverForTopicProps) {
  return (
    <Container className="page">
      <Header>
        <TitleOfHeader>Título da pesquisa</TitleOfHeader>
        <DescriptionOfHeader>
          {titleOfSurvey}
        </DescriptionOfHeader>
      </Header>
      <Main>
        <FooterIcon alt="Palindromo" src={PalindromoIcon} />
        <DateDescription>{dateOfSurvey}</DateDescription>
        <TitleOfPage>
          {titleOfTopic}
        </TitleOfPage>
        <LargeBar />
      </Main>
      <FooterBar>
        <FooterPurleDarkBar />
        <FooterPurleLightBar />
      </FooterBar>
    </Container>
  );
}

export default CoverForTopic;

import printer from '../../assets/icons/printer.svg';

import { Container, IconContainer, IconImage } from './styles';

export default function Actions() {
  // Chama o popup de impressão do próprio browser
  const onPrint = () => {
    if (typeof window.print !== 'undefined') {
      window.print();
    }
  };

  const handlePrintOnKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      onPrint();
    }
  };

  return (
    <Container>
      <IconContainer 
        title='Imprimir'
        tabIndex={1}
        onClick={onPrint}
        onKeyDown={handlePrintOnKeyDown}
      >
        <IconImage src={printer}></IconImage>
      </IconContainer>
    </Container>
  )
}
import {
  Container,
  FooterLabel,
  FlagFooterContent,
  FlagFooterPurpleGray,
  FlagFooterThesadPurple,
  ContentFooter,
} from "./styles";

interface FooterTableProps {
  label: string;
  page: number;
}

function FooterTable({ label, page }: FooterTableProps) {
  return (
    <>
      <Container>
        <ContentFooter>
          <FooterLabel>{label}</FooterLabel>
          <FooterLabel>Página {page}</FooterLabel>
        </ContentFooter>
        <FlagFooterContent>
          <FlagFooterPurpleGray />
          <FlagFooterThesadPurple />
        </FlagFooterContent>
      </Container>

    </>
  );
}

export default FooterTable;

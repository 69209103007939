import { OperatorProps } from "../types/apiResponse";

export function formatDataOperatorsPerPage(data: OperatorProps[]) {
  const operadoresHeight = 31 + 14 + 9 + 22 + 30;
  const maxHeight = 550;
  const spaceBetweenLocales = 15;
  const localeMinSize = 8.56;
  const maxLettersInLocale = 19;

  let indexAcumulator = 0;
  let counter = 0;

  const operadoresPorPagina = data.reduce(
    (acc: OperatorProps[][], operador: OperatorProps) => {
      // TODO: 9 é uma altura fixa considerando que a primeira localidade tem apenas uma linha;
      // A altura deve ser dinâmica.

      indexAcumulator += operadoresHeight;

      const locationsMeta = [];

      let amostrasTotais = 0;

      for (let i = 0; i < operador.localidades.length; i++) {
        // Tamanho da String (por caracteres)
        const locationNameLength = operador.localidades[i].nome.length;

        amostrasTotais += operador.localidades[i].amostra;

        // Verificando quantas linhas terão
        const numberOfLines = Math.ceil(
          locationNameLength / maxLettersInLocale
        );
        // O tamanho total da linha
        const spaceToIncrease =
          operador.localidades.length > 1 && i < operador.localidades.length
            ? spaceBetweenLocales
            : 0;

        const totalHeightByLine =
          localeMinSize * numberOfLines + spaceToIncrease;

        // calculo total
        const result = totalHeightByLine;

        indexAcumulator += result;

        const pageIndex = Math.floor(indexAcumulator / (maxHeight - 100));

        const meta = {
          index: i,
          page: pageIndex,
          operador: operador.operador,
          type: "",
        };

        locationsMeta.push(meta);

        /**
         * Verifica se a localização anterior existe e o indíce da página alocado a ela é diferente
         * do índice atual, o que significa que houve uma quebra de página relativo ao mesmo operador.
         */
        let prevLoc = locationsMeta[i - 1];

        if (prevLoc) {
          if (
            prevLoc.page !== locationsMeta[i].page &&
            prevLoc.operador === locationsMeta[i].operador
          ) {
            locationsMeta[i].type = "break";
          }
        }
      }

      locationsMeta.forEach((locationMeta) => {
        if (!acc[locationMeta.page]) {
          acc[locationMeta.page] = [];
        }

        let operadorIndex = acc[locationMeta.page].findIndex(
          (op) => op.operador.id === locationMeta.operador.id
        );

        if (operadorIndex < 0) {
          operadorIndex =
            acc[locationMeta.page].push({
              operador: {
                ...locationMeta.operador,
                flag: locationMeta.type === "break" ? true : false,
                position: ++counter,
              },
              localidades: [],
            }) - 1;
        }

        acc[locationMeta.page][operadorIndex].localidades.push(
          operador.localidades[locationMeta.index]
        );
        acc[locationMeta.page][operadorIndex].operador.amostrasTotais =
          amostrasTotais;
      });

      return acc;
    },
    []
  );

  return operadoresPorPagina;
}

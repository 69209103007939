import styled from "styled-components";

const Container = styled.div`
  background-color: var(--background-color-white);
  height: 100%;
  width: 100%;

  position: relative;

  @media print {
    height: 1123px;
    width: 793px;
  }
`;

const MainContent = styled.main`
  height: 555px;
  margin: -40px 20px;

  border: 1px solid var(--border-color-purple-gray);

  @media print {
    max-height: 555px;
  }
`;

export { Container, MainContent };

import TensaiLogo from "../../assets/icons/icon-tensai.svg";

import {
  Container,
  HeaderSurveyContent,
  TensaiIcon,
  SurveyContent,
  SurveyLabel,
  SurveyTitle,
  Card,
  CardContent,
  CardLabel,
  CardString,
  CardNumber,
} from "./styles";

interface HeaderSurveyProps {
  surveyTitle: string;
  surveyLocation: string;
  surveyTotalOfSamples: number;
  surveyTotalOfOperators: number;
}

function HeaderSurvey({
  surveyTitle,
  surveyLocation,
  surveyTotalOfSamples,
  surveyTotalOfOperators,
}: HeaderSurveyProps) {
  return (
    <Container>
      <HeaderSurveyContent>
        <TensaiIcon alt="Tensai" src={TensaiLogo} />

        <SurveyContent>
          <SurveyLabel>Pesquisa</SurveyLabel>
          <SurveyTitle>
            {surveyTitle}
          </SurveyTitle>
        </SurveyContent>
      </HeaderSurveyContent>

      <Card>
        <CardContent>
          <CardLabel>Local da pesquisa:</CardLabel>
          <CardString>{surveyLocation}</CardString>
        </CardContent>

        <CardContent hasBordersLeftAndRight marginRight>
          <CardLabel>Amostra presencial</CardLabel>
          <CardNumber>{surveyTotalOfSamples}</CardNumber>
        </CardContent>

        <CardContent>
          <CardLabel>Operadores</CardLabel>
          <CardNumber>{surveyTotalOfOperators}</CardNumber>
        </CardContent>
      </Card>
    </Container>
  );
}

export default HeaderSurvey;
